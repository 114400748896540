import axios from 'axios';
import { HttpStatusResponse } from '../Constants/http-status.const';
import { PaginationProperties } from '../Interfaces/app-general.interface';
import {
    RequestFormValues,
    RequestResponse,
} from '../Interfaces/request.interface';

import { createQueryByFilters } from '../Utils/general.utils';
import { AppService } from './app.service';

/**
 * Class responsible for manage the API calls and data request of the approvals review
 */
export class RequestService extends AppService {
    public async getAllRequests(
        queryParams: RequestFormValues & PaginationProperties,
    ): Promise<Array<RequestResponse> | []> {
        const query = createQueryByFilters(queryParams);
        const path = `${this.apiUrl}/${this.controllerPathRequest}${query}`;
        const token = await this.headers;
        console.log(path);
        try {
            const response = await axios.get(path, {
                headers: {
                    authorization: token.authorization,
                },
            });
            if (response.status === HttpStatusResponse.ok.status) {
                const body = response.data.body;
                return body.list;
            }
        } catch (error) {
            throw error;
        }
        return [];
    }

    public async download(
        queryParams: RequestFormValues & PaginationProperties,
    ): Promise<Array<RequestResponse> | []> {
        const query = createQueryByFilters(queryParams);
        const path = `${this.apiUrl}/${this.controllerPathRequest}/download${query}`;
        try {
            const response = await axios.get(path, {
                headers: await this.headers,
            });
            if (response.status === HttpStatusResponse.ok.status) {
                return response.data;
            }
        } catch (error) {
            throw error;
        }
        return [];
    }

    public async editRequest(
        requestId: number,
        status: string,
    ): Promise<string> {
        const path = `${this.apiUrl}/${this.controllerPathRequest}/${requestId}/${status}`;
        const token = await this.headers;
        try {
            const response = await axios({
                method: 'put',
                url: path,
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    authorization: token.authorization,
                },
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    }
}

import React, { useEffect, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import {
    Button,
    ButtonProps,
    CircularProgress,
    styled,
    // eslint-disable-next-line prettier/prettier
    TextField,
} from '@mui/material';

import { TableInner } from '../../../Components/Tables/TableInner';
import { ten, zero } from '../../../Constants/var.constants';
import {
    UserFormValues,
    // eslint-disable-next-line prettier/prettier
} from '../../../Interfaces/user.interface';
import { transformData } from '../../../Utils/general.utils';
import './request.css';
import { RequestResponse } from '../../../Interfaces/request.interface';
import { RequestService } from '../../../Services/request.service';
import toast, { Toaster } from 'react-hot-toast';
import { Download } from '@mui/icons-material';

export const Request: React.FC = () => {
    const [request, setRequest] = useState<Array<RequestResponse> | []>([]);
    const [dataTable, setDataTable] = useState(Array<Array<string>>);
    const [formData, setFormData] = useState<UserFormValues>(
        {} as UserFormValues,
    );
    const [page, setPage] = React.useState(zero);
    const [rowsPerPage, setRowsPerPage] = React.useState(ten);
    const [loading, setLoading] = useState(true);
    const [loadingAction, setLoadingAction] = useState<number | null>(null);

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        console.log(event);
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, ten));
        setPage(zero);
    };

    /**
     * Handle onChange method
     * @param key input id
     * @param value input value
     */
    const onChangeDataForm = (key, value) => {
        let newValue = value;
        if (key === 'phone') {
            newValue = encodeURIComponent(newValue);
        }
        setFormData({ ...formData, [key]: newValue });
    };

    /**
     * Method to call approvals review.
     * @param offset number
     * @param limit number
     * @returns {Promise<Array<RequestResponse>>}
     */
    const getRequestForApprovals = (
        offset: number,
        limit: number,
        afterEdit?: boolean,
    ): Promise<Array<RequestResponse | []>> => {
        !afterEdit && setLoading(true);
        const service = new RequestService();
        return new Promise((resolve) => {
            service
                .getAllRequests({
                    ...formData,
                    offset,
                    limit,
                })
                .then((response) => {
                    setRequest(response);
                    const data = transformData(
                        'id',
                        response,
                        undefined,
                        loadingAction,
                        editRquest,
                    );
                    setDataTable(data);
                    setLoading(false);
                    setLoadingAction(null);
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error);
                    setLoading(false);
                });
        });
    };

    /**
     * Method to call the approvals for export.
     * @param offset number
     * @param limit number
     * @returns {Promise<Array<WhitelistResponse>>}
     */
    const getExportData = async (
        offset: number,
        limit: number,
        afterEdit?: boolean,
    ) => {
        !afterEdit && setLoading(true);
        const service = new RequestService();
        const response = new Promise((resolve) => {
            service
                .download({
                    ...formData,
                    offset,
                    limit,
                })
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error);
                    setLoading(false);
                });
        });

        const link = `data:text/csv;base64,${await response}`;
        const tempLink = document.createElement('a');
        tempLink.href = link;
        tempLink.setAttribute('download', 'requests.csv');

        document.body.appendChild(tempLink);
        tempLink.click();

        document.body.removeChild(tempLink);
        setLoading(false);
    };

    /**
     * Method to edit status from approval request.
     * @param requestId request identifier.
     */
    const editRquest = (requestId: number, status: string) => {
        const offset = 0;
        const limit = 10;
        const service = new RequestService();
        return new Promise(() => {
            service
                .editRequest(requestId, status)
                .then(() => {
                    toast.success('Saved request!');
                    getRequestForApprovals(offset, limit);
                })
                .catch((error) => {
                    console.log(error);
                    toast.error(error);
                    setLoadingAction(null);
                });
        });
    };

    useEffect(() => {
        getRequestForApprovals(page, rowsPerPage);
    }, []);

    useEffect(() => {
        const data = transformData('id', request, undefined, loadingAction);
        setDataTable(data);
    }, [loadingAction]);

    useEffect(() => {
        const count = page * rowsPerPage;
        getRequestForApprovals(count, count + rowsPerPage);
    }, [rowsPerPage, page]);

    const headers = [
        'Approval Id',
        'Phone',
        'Status',
        'ID',
        'SSN',
        'Create At',
        'Update At',
        'Action',
    ];

    const ButtonInner = styled(Button)<ButtonProps>(() => ({
        color: '#000',
        backgroundColor: 'hsl(60deg 75% 95%)',
        '&:hover': {
            backgroundColor: 'hsl(60deg 22% 81%)',
        },
    }));

    return (
        <div className='ContainerUsers'>
            <div>
                <Toaster />
            </div>
            <h1>Approval Request</h1>
            <div className='FilterUserContainer'>
                <TextField
                    id='outlined-phone'
                    label='Phone'
                    onChange={(event) =>
                        onChangeDataForm('phone', event.target.value)
                    }
                />
                <ButtonInner
                    variant='contained'
                    onClick={() => getRequestForApprovals(zero, ten)}
                    startIcon={<SearchIcon />}
                >
                    Search
                </ButtonInner>
                <ButtonInner
                    variant='contained'
                    onClick={() => getExportData(page, rowsPerPage)}
                    startIcon={<Download />}
                >
                    Export data
                </ButtonInner>
            </div>
            {loading ? (
                <CircularProgress
                    color='inherit'
                    className='LoadingTable'
                    style={{ marginTop: '50px' }}
                />
            ) : (
                <TableInner
                    headers={headers}
                    data={dataTable}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                />
            )}
        </div>
    );
};

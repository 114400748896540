import axios from 'axios';

import { HttpStatusResponse } from '../Constants/http-status.const';
import { PaginationProperties } from '../Interfaces/app-general.interface';
import { createQueryByFilters } from '../Utils/general.utils';
import {
    TransactionFormValues,
    // eslint-disable-next-line prettier/prettier
    TransactionResponse,
} from '../Views/Admin/TransactionList/transactionlist.type';
import { AppService } from './app.service';

/**
 * Class responsible for manage the API calls and data request of the Transactions
 */
export class TransactionsService extends AppService {
    public async getAllTransactions(
        queryParams: TransactionFormValues & PaginationProperties,
    ): Promise<Array<TransactionResponse> | []> {
        const query = createQueryByFilters(queryParams);
        const path = `${this.apiUrl}/${this.controllerPathTransactions}${query}`;
        try {
            const response = await axios.get(path, {
                headers: await this.headers,
            });
            if (response.status === HttpStatusResponse.ok.status) {
                const body = response.data.body;
                return body.list;
            }
        } catch (error) {
            throw error;
        }
        return [];
    }

    public async download(
        queryParams: TransactionFormValues & PaginationProperties,
    ): Promise<Array<TransactionResponse> | []> {
        const query = createQueryByFilters(queryParams);
        const path = `${this.apiUrl}/${this.controllerPathTransactions}/download${query}`;
        try {
            const response = await axios.get(path, {
                headers: await this.headers,
            });
            if (response.status === HttpStatusResponse.ok.status) {
                return response.data;
            }
        } catch (error) {
            throw error;
        }
        return [];
    }
}

import { FormLabelsData } from '../../../Components/Tables/FilterForm/filterform.type';

export const headers = [
    'Transaction Id',
    'Barcode',
    'BTC Address',
    'Teller',
    'Stage',
    'Amount',
    'User Id',
    'Create At',
];

export enum StagesEnum {
    Received = 'Received',
    Completed = 'Completed',
    InProcess = 'In Process',
    Validated = 'Validated',
    Invalid = 'Invalid',
    Failed = 'Failed',
    Queued = 'Queued',
    Sent = 'Sent',
}

/**
 * Stages catalog.
 */
export const stagesCatalog = {
    catalog: [
        {
            stageId: 1,
            name: StagesEnum.Received,
            value: 'received',
        },
        {
            stageId: 2,
            value: 'completed',
            name: StagesEnum.Completed,
        },
        {
            stageId: 3,
            value: 'inprocess',
            name: StagesEnum.InProcess,
        },
        {
            stageId: 4,
            value: 'validated',
            name: StagesEnum.Validated,
        },
        {
            stageId: 5,
            value: 'invalid',
            name: StagesEnum.Invalid,
        },
        {
            stageId: 6,
            value: 'failed',
            name: StagesEnum.Failed,
        },
        {
            stageId: 7,
            value: 'queued',
            name: StagesEnum.Queued,
        },
        {
            stageId: 8,
            value: 'sent',
            name: StagesEnum.Sent,
        },
    ],
    error: false,
    errorMessage: '',
    isLoading: false,
};

/**
 * It defines the form inputs, labels ad validations
 */
export const formProviderTransactions: Array<FormLabelsData> = [
    {
        placeholder: 'Barcode',
        label: 'Barcode',
        elementId: 'barcode',
        type: 'text',
        max: 250,
        sizeClass: '250px',
        styleClass: 'TransactionItem',
    },
    {
        placeholder: 'BTC Address',
        label: 'BTC Address',
        elementId: 'address',
        type: 'text',
        max: 250,
        sizeClass: '250px',
        styleClass: 'TransactionItem',
    },
    {
        placeholder: 'Teller',
        label: 'Teller',
        elementId: 'teller',
        type: 'text',
        max: 250,
        sizeClass: '250px',
        styleClass: 'TransactionItem',
    },
    {
        label: 'Stage',
        elementId: 'stage',
        type: 'select',
        catalog: 'stages',
        catalogId: 'value',
        catalogName: 'name',
        aliasKey: 'stage',
        sizeClass: '250px',
        styleClass: 'TransactionItem label-background-white',
    },
];
